.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper h3 {
  font-size: 1em; /* 32px */
  margin-bottom: 5px;
}

.button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
}

.button:hover {
  background-color: #555;
}

.container {
  background-color: #eee;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 10px;
}

.wrapper p {
  font-size: 1em;
  margin: 5px;
}

.pulsingCircle {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 50%;
  animation: pulse 1s infinite;
  margin-left: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}