.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper h3 {
  font-size: 1em; /* 32px */
  margin: 0;
}

.button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
}

.button:hover {
  background-color: #555;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.candidateSelect {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.candidateSelect label {
  font-weight: bold;
  flex: 0 0 80px; /* Adjust the label width */
}

.candidateSelect select {
  flex: 1;
  border-radius: 8px;
  border: 0;
  padding: 10px;
  margin: 5px;
  font-size: 1em;
  background-color: #eee;
}

.wrapper textarea {
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 0;
  font-size: 1em;
  background-color: #eee;
}

.number {
  color: white;
  background-color: black;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.8em;
}