.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  margin-bottom: 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
}

.button:hover {
  background-color: #555;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.wrapper textarea {
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  border: 0;
  font-size: 1em;
  background-color: #eee;
}

.wrapper label {
  margin: 5px 0 5px 0;
}

.wrapper select {
  flex: 1;
  border-radius: 8px;
  border: 0;
  padding: 10px;
  margin: 5px;
  font-size: 1em;
  background-color: #eee;
}

.wrapper input[type="text"] {
  border-radius: 8px;
  border: 0;
  padding: 10px;
  margin: 5px;
  font-size: 1em;
  background-color: #eee;
}

.wrapper ul {
  list-style-type: none;
  padding: 0;
}

.wrapper li {
  margin: 5px;
}

.message {
  color: #aaa;
  margin-top: 5px;
  font-size: 1em;
  text-align: center;
  width: 100%;
}

.wrapper h3 {
  font-size: 1em; /* 32px */
  margin-bottom: 5px;
}

.wrapper p {
  font-size: 1em;
  margin: 5px;
}