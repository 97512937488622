.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.wrapper li {
  margin: 0;
}

.wrapper h3 {
  font-size: 1em; /* 32px */
  margin: 0;
}

.button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
}

.button:hover {
  background-color: #555;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.message {
  color: #aaa;
  margin-top: 5px;
  font-size: 1em;
  text-align: center;
  width: 100%;
}

.wrapper input[type="text"] {
  padding: 0;
  margin: 0;
  font-size: 1em;
  border: 0;
  outline: none;
}

.addFeature {
  margin: 0.5em;
  cursor: pointer;
  font-size: 1em;
}

.priorityOptions {
  margin-left: 1em;
}

.priorityOptions label {
  margin-right: 1em;
}
