.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper h2 {
  font-size: 1.5em;
  /* 32px */
  margin-bottom: 0.5em;
  /* 8px */
}

.wrapper p {
  font-size: 1.25em;
  /* 20px */
  margin-bottom: 2em;
  /* 32px */
}

.button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.25em;
  /* 20px */
}

.button:hover {
  background-color: #555;
}